import React from 'react'

const textSuccess = "Waiting for others to join..."
const textError = "Joining network..."

export default function SocketStatus({ status, errors }){
    return (
        <div
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          backgroundColor: status ? "#4caf50" : "#e91e63",
          color:'white',
          zIndex: 999999,
        }}
      >
        
        <h1 style={{ padding: '20px', fontSize: '25px', fontWeight: 100 }}>
          {errors ? errors : status ? textSuccess : textError}
        </h1>
      </div>
    )
}