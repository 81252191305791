import React from 'react'
import Player from './Player'
import {
    checkStream,
    debug
} from '../helpers'

export default function RenderPeers({ entry, mute }) {
  const [peerId, peer] = entry;
  if (peer.stream) {
    const { hasAudio, hasVideo } = checkStream(peer.stream);
    if (hasAudio || hasVideo) {
      debug("[Render peer]", peerId, peer, entry);
      debug(
        peerId + " [audio] %s [video] %s [stream] %s ",
        hasAudio,
        hasVideo,
        peer.stream
      );
      return (
        <div className={"guest-feed"}>
          {
            <div className='audio-only'>
            
            
            </div>
            
          }
          <Player
            hasAudio={hasAudio}
            hasVideo={hasVideo}
            stream={peer.stream}
            muted={ mute }
            className="video"
            poster="https://d3lq8p6p6r1qxf.cloudfront.net/5d2718082d144ae6a68fba72fe808fd3.png"
          />
        </div>
      );
    }
  }

  return null;
}