export const ICE_SERVERS = [
	{ urls: 'stun:stun.l.google.com:19302' },
	{
		urls: 'turn:turn.marrakech.io:3478?transport=udp',
		username: 'akNSkas5414aKKAsk87S12', credential: '4575a1s2az14t74gd'
	},
	{
		urls: 'turn:turn.marrakech.io:3478?transport=tcp',
		username: 'akNSkas5414aKKAsk87S12', credential: '4575a1s2az14t74gd'
	}
];

export const TRICKLE = false

export const JOIN = 'join'
export const PEER = 'peer'
export const SIGNAL = 'signal'
export const UNPEER = 'unpeer'
export const PING = 'ping'
export const PONG = 'pong'
export const ERROR = 'error'
export const AVAILABLE = 'available'


export const WS_SERVER = process.env.NODE_ENV === 'development'
	? 'ws://localhost:1337' : 'wss://signal.btt.ma'