import { useState, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import Sockette from "sockette";

import {
  PONG,
  AVAILABLE,
  WS_SERVER
} from "./constants";

import { serialize, unserialize, debug } from "./helpers";

let ws

function SpeedNetworking({ match }) {
  const {userId} = match.params
  const history = useHistory();
  const [foundPeer, setFoundPeer] = useState()

  //Start ws client
  function socketClient() {
    return new Sockette(WS_SERVER, {
      timeout: 5e3,
      maxAttempts: Infinity,
      onopen: onOpen,
      onmessage: onmessage,
      onreconnect: (e) => debug("Reconnecting...", e),
      onmaximum: (e) => {
        debug("Stop Attempting!", e);
        debug("Reloading the App", e);
        window.location.reload();
      },
      onclose: (e) => {
        debug("Closed!", e);
        ws.reconnect();
      },
      onerror: (e) => {
        debug("Error:", e);
        window.location.reload();
      },
    });
  }

  useEffect(() => {
    if (!ws) {
      debug("Starting socket client");
      ws = socketClient();
    }

    return function cleanup() {
      if (ws && ws.close) {
        try {
          ws.close();
        } catch (error) {
          debug(error);
        }
      }
    };
    // eslint-disable-next-line
  }, []);

  //  ws helpers
  function onOpen() {
    try {
      debug("[onOpen] Speed Networking mode...");
      ws.send(serialize({
        userId,
        action: AVAILABLE
      }));
    } catch (error) {
      
    }

  }

  //  ws incoming handler
  function onmessage(message) {
    try {
      const {
        action,
        peerId,
        roomId
      } = unserialize(message.data);
      switch (action) {
        case PONG:
          debug(PONG, peerId, 'speed-Networking');
          break;
        case AVAILABLE:
          debug(AVAILABLE, roomId)
          setFoundPeer(true)
          setTimeout(() => {
            debug('Redirect to room: ', roomId)
            history.push('/speednetwork/videochat/'+roomId)
          }, 3000)
          break
        default:
          debug(unserialize(message.data))
          break
          
      }
    } catch (error) {
      debug(error);
    }
  }

  return (
    <div className="lobby">
      <h1>
      {
        foundPeer ? 'connecting...' : 'Please wait for a match to connect...'
      }
      </h1>
    </div>
  );
}

export default withRouter(SpeedNetworking);
