import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import VideoChat from './VideoChat';
import SpeedNetworking from './SpeedNetworking'

export default function App() {
  return (
    <Router forceRefresh={ true }>
      <Switch>

        <Route path="/speednetwork/videochat/:id">
            <VideoChat speednetworking/>
        </Route>
                
        <Route path="/speednetwork/:userId">
            <SpeedNetworking />
        </Route>
          
        
        <Route path="/:id">
            <VideoChat />
        </Route>

        
        <Route path="*">
            <h1>Are you lost ?</h1>
        </Route>
          
         
        </Switch>
    </Router>
  );
}