import React, { useEffect, useRef, useState} from "react";
import { debug } from '../helpers'


export default function Player({
  stream,
  muted = false,
  className,
  width = 320,
  height = 240,
  poster,
}) {
  const videoRef = useRef(null);
  const [src, setSrc] = useState();
  useEffect(() => {
    try {
      if (videoRef && videoRef.current) {
        videoRef.current.srcObject = stream;
        //videoRef.current.play();
        //alert('videoRef')
      }
    } catch (e) {
      debug(e);
      setSrc(window.URL.createObjectURL(stream));
    }
  }, [videoRef, stream]);

  return (
    <video
      src={src}
      width={width}
      height={height}
      autoPlay
      ref={videoRef}
      controls={false}
      muted={muted}
      className={className}
      poster={poster}
      playsinline
    />
  );
}
