export function getRoomName() {
  let roomName = window.location.pathname.split('/').filter(l => l)[0]
  
  if (!roomName || roomName === '') {
      const randomName = () =>
        Math.random()
        .toString(36)
        .substr(2, 15);
    roomName = randomName();
    return roomName

    }
    return roomName.trim();
}; 


export function unserialize (str) {
  try {
    return JSON.parse(str)
  } catch (error) {
    debug('unserialize: ', error)
  }
}

export function serialize (str) {
  try {
    return JSON.stringify(str)
  } catch (error) {
    debug('serialize: ', error)
  }
}

export function checkStream(stream) {
  var hasMedia={hasVideo:false,hasAudio:false};
  try {
  
    if(stream.getAudioTracks().length)// checking audio presence
       hasMedia.hasAudio=true;
  
    if(stream.getVideoTracks().length)// checking video presence
       hasMedia.hasVideo=true;
  
    
  } catch (error) {
    debug(error)
  }

  return hasMedia; 

}

export const debug = process.env.NODE_ENV === 'development' ?  console.log : ()=>{}