import React from 'react'
//import image from '../images/user-allow.png'

export default function AskPermission() {
    return (
        <div className='AskPermission'>
            <div className='Title'>
               You haven't Allowed Access to Your Microphone or Camera
            </div>
         
            <div className='reload'>
                <button onClick={()=>window.location.reload()}>Try Again</button>
            </div>
           
        <p>If you are stuck here, try Private Navigation</p>

        
        </div>
    )
}