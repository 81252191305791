import React from 'react'
import Player from './Player'

export default function MyFeed({ hasAudio,  hasVideo, amIAlone, stream}){
    return (
      <div className={amIAlone ? 'my-feed alone' : "my-feed"}>
      <Player
        width="110"
        height="140"
        hasAudio={hasAudio}
        hasVideo={hasVideo}
        stream={stream}
        muted
        className={amIAlone ? 'video-without-radius' : "video-width-radius"}
        poster="https://d3lq8p6p6r1qxf.cloudfront.net/5d2718082d144ae6a68fba72fe808fd3.png"
      />
    </div>
    )
}