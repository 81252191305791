/**
 * @Idee
 * Quand l'appel coupe, passer
 * automatiquement au téléphone (SIP)
 *
 */

import { useState, useEffect, useRef } from "react";
import SocketStatus from "./components/SocketStatus";
import GoBack from './components/GoBack'
import Myfeed from "./components/MyFeed";
import RenderPeers from "./components/RenderPeers";
import AskPermission from "./components/AskPermission";
import Peer from "simple-peer";
import Sockette from "sockette";

import {
  JOIN,
  PEER,
  UNPEER,
  PING,
  PONG,
  SIGNAL,
  WS_SERVER,
  ICE_SERVERS,
  TRICKLE,
  ERROR
} from "./constants";

import { getRoomName, serialize, unserialize, debug } from "./helpers";
import politeSound from "./sounds/polite.wav";
import taptapSound from "./sounds/taptap.wav";

import "./VideoChat.css";

let ws, keepAlive, room;

function App({ speednetworking }) {
  const [me, setMe] = useState(null);
  const [peers, setPeers] = useState({});
  const [stream, setStream] = useState(null);
  const [hasVideo, setHasVideo] = useState(null);
  const [appReady, setAppReady] = useState(false);
  const [hasAudio, setHasAudio] = useState(null);
  const [socketConnection, setSocketConnection] = useState(false);
  const [errors,setErrors] = useState()

  const peersRef = useRef();
  peersRef.current = peers;

  //= =================================
  //  Start ws client
  function socketClient() {
    return new Sockette(WS_SERVER, {
      timeout: 5e3,
      maxAttempts: Infinity,
      onopen: onOpen,
      onmessage: onmessage,
      onreconnect: (e) => debug("Reconnecting...", e),
      onmaximum: (e) => {
        debug("Stop Attempting!", e);
        setSocketConnection(false);
        debug("Reloading the App", e);
        window.location.reload()
      },
      onclose: (e) => {
        debug("Closed!", e);
        setSocketConnection(false);
        if (!speednetworking) {
          ws.reconnect();
        }
      },
      onerror: (e) => {
        debug("Error:", e);
        setSocketConnection(false);
      },
    });
  }

  useEffect(() => {
    if (stream && !ws) {
      debug("Starting socket client");
      ws = socketClient();

      try {
        keepAlive = setInterval(() => {
          debug("ping")
          ws.json({ action: PING });
        }, 10*1000);
      } catch (error) {
        debug(error);
      }
    }

    return function cleanup() {
      if (ws && ws.close) {
        try {
          debug("Cleaning Peers list");
          setPeers({});
          clearInterval(keepAlive);
          ws.close();
        } catch (error) {
          debug(error)
        }
  
      }
    };
    // eslint-disable-next-line
  }, [stream]);



  //= =================================
  //  ws helpers
  function onOpen() {
    try {
      room = getRoomName();
      ws.send(serialize({ room, action: JOIN }));
      setSocketConnection(true);
    } catch (error) {
      debug(error);
    }
  }

  //= =================================
  //  ws incoming handler
  function onmessage(message) {
    setSocketConnection(true);
    try {
      const { action, signal, peerId, message:errorMessage } = unserialize(message.data);
      const peer = peersRef.current[peerId];
      switch (action) {
        case PONG:
          debug(PONG, peerId, room);
          break;
        case ERROR:
          debug(ERROR, errorMessage, room);
          setErrors(errorMessage)
          break;
        case JOIN:
          debug(JOIN, peerId, room);
          setMe(peerId);
          ws.send(serialize({ action: PEER, room }));
          break;
        case PEER:
          debug(PEER, peerId);
          if (peerId === me) {
            return debug("Peer is me", peerId);
          }
          debug("createPeer", peerId, stream);
          createPeer(peerId, true, stream);
          break;
        case SIGNAL:
          debug(SIGNAL, peerId);
          if (!peer) {
            debug("Lets create a new peer", SIGNAL);
            createPeer(peerId, false, stream);
          }
          debug("Signal peersRef.current[peerId]", peersRef.current[peerId]);
          signalPeer(peersRef.current[peerId], signal);
          break;
        case UNPEER:
          debug(UNPEER, peerId);
          unpeer(peerId);
          ws.close()
          break;
        default:
          debug('[UNHANDLED MESSAGE] '+message.data);
          break;
      }
    } catch (error) {
      debug(error);
    }
  }

  //= =================================
  //  Local Stream (audio video)
  async function getLocalStream() {
    let inStream = null;
    
    const constraints = {
      video: {
        facingMode: 'user',
        height: { min: 360, ideal: 720, max: 1080 }
      },
      audio: true
    };

    try {
      inStream = await navigator.mediaDevices.getUserMedia(constraints);

      if (inStream) {
        setStream(inStream);
        setHasVideo(true);
        setHasAudio(true);
        setAppReady(true);
      }

      debug("Audio & Video [Ok]", inStream);
    } catch (err) {
      // falling back to audio only
      debug("[Audio & Video] " + err);
      try {
        debug("[Falling back to audio only] ");
        inStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });

        if (inStream) {
          setHasAudio(true);
          setHasVideo(false);
          setAppReady(true);
          setStream(inStream);
          debug("[Local stream]", inStream);
        }
      } catch (error) {
        setHasAudio(false);
        setHasVideo(false);
        setAppReady(true);
        debug("[Audio] " + error);
        inStream = null;
        setStream(null);
      }
    }
  }

  useEffect(() => {
    getLocalStream();
    // eslint-disable-next-line
  }, []);

  //= =================================
  //  Create Webrtc Peer helper
  //  peerId: signal destination
  function createPeer(peerId, initiator, stream) {
    debug("[createPeer] ", peerId, initiator, {
      initiator: initiator,
      config: { iceServers: ICE_SERVERS },
      trickle: TRICKLE,
      stream,
    });

    let peer = new Peer({
      reconnectTimer: 3000,
      initiator: initiator,
      iceTransportPolicy: "relay",
      config: { iceServers: ICE_SERVERS },
      trickle: TRICKLE,
      stream,
    });

    peer.on("signal", (signal) => {
      debug("signal", signal, stream);

      ws.send(
        JSON.stringify({
          room: room,
          action: SIGNAL,
          to: peerId,
          signal,
        })
      );
    });

    peer.on("stream", (stream) => {
      debug("[stream] Got a peer stream", peerId, stream);
      peer.stream = stream;
      debug("[stream] Adding new peer", peerId);
      addToPeers(peerId, peer);
    });

    peer.on("connect", () => {
      debug("[connect] Connected to peer", peerId);
      debug("[connect] Adding new peer", peerId);
      addToPeers(peerId, peer);

      peer.send(
        JSON.stringify({
          msg: "Yo " + peerId,
        })
      );

      audioNotification(politeSound);
    });

    peer.on("data", (data) => {
      debug("Data from peer", peerId, unserialize(data));
    });

    peer.on("error", (e) => {
      debug("Peer error %s:", peerId, e);
      debug("Destroying peer %s:", peerId);
      unpeer(peerId);
    });

    debug("Adding new peer", peerId);
    addToPeers(peerId, peer);

    return peer;
  }

  // signal peer
  function signalPeer(peer, data) {
    try {
      peer.signal(data);
    } catch (e) {
      debug("sigal error", e);
    }
  }

  function unpeer(peerId) {
    debug("Unpeering", peerId);
    audioNotification(taptapSound);
    try {
      const all = { ...peersRef.current };

      if (all[peerId]) {
        debug("destroing and deleting ", all[peerId]);
        all[peerId].destroy();
        delete all[peerId];
        setPeers(all);
        debug("[unpeer] List Peers", all);
      } else {
        debug("Cannot find peer", peerId);
      }
    } catch (error) {
      debug("unpeer error", error);
    }
  }

  function audioNotification(sound) {
    debug("[Audio] notification");
    const snd = new Audio(sound);
    return snd.play();
  }

  function addToPeers(peerId, peer) {
    let newPeers = { ...peersRef.current };
    newPeers[peerId] = peer;
    debug("[Peers] Add new", newPeers);
    setPeers(newPeers);
  }

  function countPeers() {
    try {
      return Object.entries(peers).length;
    } catch (error) {
      return 0;
    }
  }

  return (
 
      <div className="App">
        {(hasAudio || hasVideo) && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Myfeed
              hasAudio={hasAudio}
              hasVideo={hasVideo}
              stream={stream}
              amIAlone={countPeers() === 0}
          />
          

            {peers &&
              Object.entries(peers).map((entry, key) => (
                <RenderPeers
                  entry={entry}
                  key={"entry-key-" + key}
                />
              ))}

           
          </div>
        )}
        {
           !speednetworking && countPeers() === 0 && appReady && (hasAudio || hasVideo) && <SocketStatus status={socketConnection} errors={ errors } />
      }
      
      {
          speednetworking && appReady && (hasAudio || hasVideo) && <GoBack />
        }

        {appReady && !hasAudio && !hasVideo &&  <AskPermission />}
      </div>
    
  );
}

export default App;
